import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from './useAnalytics';

export const useTrackPageView = () => {
  const location = useLocation();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname, trackPageView]);
};
