import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

const FullPageSpinner = () => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ delay: 0.3, duration: 0.2 }}
    className="fixed inset-0 z-50 flex items-center justify-center bg-body"
    role="alert"
  >
    <FontAwesomeIcon icon={faSpinnerThird} spin className="h-8 w-8 text-primary" />
  </motion.div>
);

export default FullPageSpinner;
