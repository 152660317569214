/* eslint-disable */
import { AlertPolicyCondition, conditionsConfig, ConditionsMeasurementConfig } from '../modules/Alerts/forms';
import { ConditionMatchType } from '../enums';
import { AlertPolicy } from '../api';

const ConditionBuilderService = {
  generateJsonLogic(conditionMatch: ConditionMatchType, conditionArray: AlertPolicyCondition[]) {
    return {
      [conditionMatch]: conditionArray.map((condition) => {
        const config = this.getMeasurementConditionConfig(condition.key);

        return {
          [condition.operator]: [
            { var: condition.key },
            config?.valueKey === 'stringValue' ? condition.stringValue : parseFloat(condition.numberValue?.toString() || ''),
          ],
        };
      }),
    };
  },

  mapJsonLogicToFormValues(conditions: AlertPolicy['conditions']): [ConditionMatchType, AlertPolicyCondition[]] {
    const conditionMatch = Object.keys(conditions)[0] as ConditionMatchType;
    const conditionArray = (conditions[conditionMatch] as any).map((condition: any) => {
      const operator = Object.keys(condition)[0];
      const key = condition[operator][0].var;
      const config = this.getMeasurementConditionConfig(key);
      const valueKey = config?.valueKey || 'numberValue';

      return {
        operator,
        key,
        [valueKey]: condition[operator][1],
      };
    });

    return [conditionMatch, conditionArray];
  },

  getMeasurementConditionConfig(measurementKey: string) {
    const flatConfig = Object.entries(conditionsConfig).reduce(
      (prev, [, measurements]) => [
        ...prev,
        ...Object.entries(measurements).map(([key, config]) => ({
          key,
          config,
        })),
      ],
      [] as Array<{ key: string; config: ConditionsMeasurementConfig }>,
    );

    return flatConfig.find(({ key }) => key === measurementKey)?.config;
  },
};

export default ConditionBuilderService;
