import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  className?: string;
};

const InlineSpinner = ({ className }: Props) => <FontAwesomeIcon icon={faSpinnerThird} spin role="progressbar" className={className} />;

export default InlineSpinner;
