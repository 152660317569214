/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import { useCallback } from 'react';

import { UserProfile } from '../api';
import { config } from '../config';
import { eventName } from '../const';

import { useMixpanel } from './useMixpanel';

export const useAnalytics = () => {
  const mixpanel = useMixpanel();

  const track = useCallback(
    (event: string, properties?: Record<string, string | number | boolean>) => {
      if (!config.telemetryEnabled) {
        return;
      }

      if (mixpanel) {
        mixpanel.track(event, properties);
      }
    },
    [mixpanel],
  );

  const identify = useCallback(
    (user: UserProfile) => {
      if ((window as any).Beacon) {
        (window as any).Beacon('identify', {
          name: `${user.firstName} ${user.lastName}`.trim(),
          email: user.email,
          avatar: user?.avatarUrl,
        });
      }

      if (!config.telemetryEnabled) {
        return;
      }

      if (mixpanel) {
        mixpanel.identify(user.id);
        mixpanel.people.set({
          $email: user.email,
          $name: `${user.firstName} ${user.lastName}`.trim(),
          $avatar: user?.avatarUrl,
        });
      }
    },
    [mixpanel],
  );

  const trackPageView = useCallback(
    (path: string) => {
      if (!config.telemetryEnabled) {
        return;
      }

      if (mixpanel) {
        mixpanel.track(eventName.page.viewed, { path });
      }
    },
    [mixpanel],
  );

  return { track, identify, trackPageView };
};
