import { IncidentStatus } from '../../../enums';
import { cn } from '../../../utils';

type Props = {
  status: IncidentStatus;
};

const labelMap: Record<IncidentStatus, string> = {
  [IncidentStatus.OPEN]: 'Open',
  [IncidentStatus.CLOSED]: 'Closed',
};

const classNameMap: Record<IncidentStatus, string> = {
  [IncidentStatus.OPEN]: 'border-error-300 text-error-500',
  [IncidentStatus.CLOSED]: 'border-slate-300 text-slate-700',
};

const IncidentStatusBadge = ({ status }: Props) => (
  <div
    className={cn(
      'inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md border bg-white px-1.5 py-0.5 text-center text-xs font-medium',
      classNameMap[status],
    )}
    role="status"
  >
    {labelMap[status]}
  </div>
);

export default IncidentStatusBadge;
