import { ServerStatus } from '../../../enums';
import { Badge, BadgeVariant } from '../../ui';

type Props = {
  status: ServerStatus;
};

const labelMap: Record<ServerStatus, string> = {
  [ServerStatus.Up]: 'Up',
  [ServerStatus.Down]: 'Down',
  [ServerStatus.Inactive]: 'Inactive',
};

const variantMap: Record<ServerStatus, BadgeVariant> = {
  [ServerStatus.Up]: 'positive',
  [ServerStatus.Down]: 'negative',
  [ServerStatus.Inactive]: 'ghost',
};

const ServerStatusBadge = ({ status }: Props) => <Badge variant={variantMap[status]} label={labelMap[status]} />;

export default ServerStatusBadge;
