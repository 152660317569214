import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '..';
import { cn, openHelpSidebar } from '../../../utils';

type Props = {
  title?: string;
  description?: string;
  className?: string;
  hideButtons?: boolean;
};

const DataErrorScreen = ({ title, description, className, hideButtons }: Props) => (
  <div className="flex h-full items-center justify-center">
    <div className={cn('flex flex-col items-center justify-center overflow-hidden py-10', className)}>
      <div className="flex max-w-md flex-col items-center px-4">
        <div className="mb-6">
          <div className="relative flex h-9 w-9 items-center justify-center rounded-full bg-warning-100 ring-8 ring-warning-50">
            <div className="absolute -z-1 h-24 w-24 rounded-full border border-slate-200 opacity-40" />
            <div className="absolute -z-1 h-40 w-40 rounded-full border border-slate-200 opacity-30" />
            <div className="absolute -z-1 h-56 w-56 rounded-full border border-slate-200 opacity-20" />
            <div className="absolute -z-1 h-72 w-72 rounded-full border border-slate-200 opacity-10" />
            <FontAwesomeIcon icon={faExclamationCircle} className="h-5 text-warning-600" />
          </div>
        </div>
        <h2 className="z-10 mb-1 text-center font-semibold text-slate-900">{title ?? 'Data could not be loaded'}</h2>
        <p className="z-10 mb-6 text-center text-sm text-slate-600">
          {description ??
            'There was an issue loading data for this screen. Please refresh the page to try again, or get in touch if the problem persists.'}
        </p>
        {!hideButtons && (
          <div className="z-10 flex items-center gap-3">
            <Button className="u-btn u-btn--default" onClick={() => openHelpSidebar()}>
              Contact support
            </Button>
            <Button type="button" className="u-btn u-btn--primary" onClick={() => window.location.reload()}>
              Refresh page
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default DataErrorScreen;
