import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormikProps } from 'formik';

import { ErrorResponse } from '../../../../api';
import { InputField, SubmitButton } from '../../../../components';

type Props<T> = FormikProps<T> & {
  error: Nullable<ErrorResponse>;
};

export const ResetPasswordForm = <T,>({ error, isSubmitting }: Props<T>) => (
  <>
    <Form noValidate spellCheck={false} aria-label="Sign in to StackScout" className="mb-4 flex flex-col gap-4">
      <div className="flex flex-col gap-1.5">
        <label htmlFor="email" id="email" className="w-full text-sm font-medium text-slate-700">
          Password
        </label>
        <InputField type="email" placeholder="name@example.com" name="email" className="w-full" />
      </div>
      <SubmitButton type="submit" isSubmitting={isSubmitting} className="u-btn u-btn--primary w-full">
        Request password reset
      </SubmitButton>
    </Form>

    {error && (
      <div className="flex rounded-xl border border-error-300 bg-error-25 p-4 text-sm text-error-700">
        <div className="mr-3">
          <div className="inline-flex rounded-full border border-error-300 p-0.5 ring-1 ring-error-300 ring-opacity-50 ring-offset-2">
            <FontAwesomeIcon icon={faExclamationCircle} className="h-4 w-auto text-error-600" />
          </div>
        </div>
        <div>
          <div className="font-semibold">Password reset could not be requested</div>
          <div>{error.message ?? 'Something went wrong. Please try again later.'}</div>
        </div>
      </div>
    )}
  </>
);
