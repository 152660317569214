import { RoleName } from '../enums';

export const employeeRoles = [RoleName.Administrator, RoleName.Instructor, RoleName.Owner, RoleName.OperationsManager].map((r) =>
  r.toString(),
);

export const operationsRoles = [RoleName.Administrator, RoleName.Owner, RoleName.OperationsManager].map((r) => r.toString());
export const roleNameLabelMap: Record<string, string> = {
  [RoleName.Owner.toString()]: 'Owner',
  [RoleName.Administrator.toString()]: 'Administrator',
  [RoleName.OperationsManager.toString()]: 'Operations Manager',
  [RoleName.Instructor.toString()]: 'Instructor',
};
