import { ReactNode } from 'react';

import { cn } from '../../../utils';
import { InlineSpinner } from '../InlineSpinner';

import { BadgeVariant } from './types';

type Props = {
  variant?: BadgeVariant;
  label: ReactNode;
  minWidth?: number;
  isLoading?: boolean;
};

const colorClassNameMap: Record<BadgeVariant, string> = {
  default: '',
  positive: 'bg-green-500',
  negative: 'bg-red-500',
  ghost: 'bg-slate-300',
};

const Badge = ({ variant, label, minWidth, isLoading }: Props) => (
  <div
    className="inline-flex h-6 items-center justify-center gap-1.5 whitespace-nowrap rounded-md border border-slate-300 bg-white px-1.5 py-0.5 text-center text-xs font-medium text-slate-700"
    style={{ minWidth }}
    role="status"
  >
    {variant && variant !== 'default' && !isLoading && <div className={cn('h-2 w-2 rounded-full', colorClassNameMap[variant])} />}
    {isLoading && <InlineSpinner className="h-2 w-2 text-primary" />}
    {label}
  </div>
);

export default Badge;
