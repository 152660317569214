import { AsyncPage, DataErrorScreen } from '../../../../components';
import { ReturnToSignInLink } from '../../components';

import { ChangePassword } from './ChangePassword';

const ChangePasswordPage = () => (
  <AsyncPage
    title="Change password"
    Component={ChangePassword}
    ErrorElement={
      <>
        <DataErrorScreen
          title="Invalid or expired token"
          description="The token you provided is invalid or has expired. Please request a new one."
          hideButtons
        />
        <ReturnToSignInLink />
      </>
    }
  />
);

export default ChangePasswordPage;
