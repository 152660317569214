type Config = {
  environment: string;
  apiUrl: string;
  clientApp: 'webapp' | 'bookings';
  telemetryEnabled: boolean;
  sentryDsn: string;
  mixpanelToken: string;
  version: string;
};

export const config: Config = {
  environment: import.meta.env.VITE_ENVIRONMENT as string,
  apiUrl: import.meta.env.MODE === 'test' ? 'https://api.vitest.stackscout.com' : String(import.meta.env.VITE_API_URL),
  clientApp: 'webapp',
  telemetryEnabled: import.meta.env.VITE_TELEMETRY_ENABLED === 'true',
  sentryDsn: String(import.meta.env.VITE_SENTRY_DSN),
  mixpanelToken: String(import.meta.env.VITE_MIXPANEL_TOKEN),
  version: import.meta.env.VITE_VERSION ? String(import.meta.env.VITE_VERSION) : 'dev',
};
