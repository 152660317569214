import * as RadixTooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren, ReactNode } from 'react';

type Props = {
  title: ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
};

const Tooltip = ({ title, side = 'top', children }: PropsWithChildren<Props>) => (
  <RadixTooltip.Root>
    <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        className="data-[state=delayed-open]:data-[side=top]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=right]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=left]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideDownAndFade max-w-xs select-none rounded-[4px] bg-body/90 px-3 py-[5px] text-xs leading-normal text-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
        sideOffset={2}
        side={side}
      >
        {title}
        <RadixTooltip.Arrow className="fill-body/90" />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
);

export default Tooltip;
