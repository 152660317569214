import { ServerStatus } from '../../../enums';

import { ConditionOperatorGroup, ConditionsConfig } from './alert-policy-form';

export const conditionsConfig: ConditionsConfig = {
  Host: {
    status: {
      label: 'State',
      operators: ConditionOperatorGroup.Selection,
      defaultOperator: '==',
      valueKey: 'stringValue',
      defaultValue: {
        stringValue: ServerStatus.Down,
        numberValue: undefined,
      },
      input: 'Select',
      selectOptions: [
        { key: ServerStatus.Down, label: 'Down' },
        { key: ServerStatus.Inactive, label: 'Inactive' },
        { key: ServerStatus.Up, label: 'Up' },
      ],
    },
  },
  Memory: {
    'memory.usage': {
      label: 'Memory used (%)',
      defaultOperator: '>',
      valueKey: 'numberValue',
      defaultValue: {
        stringValue: undefined,
        numberValue: '',
      },
      operators: ConditionOperatorGroup.Numeric,
      input: 'Number',
      inputPlaceholder: 'e.g. 80',
    },
    'memory.used': {
      label: 'Memory used (MB)',
      defaultOperator: '>',
      valueKey: 'numberValue',
      defaultValue: {
        stringValue: undefined,
        numberValue: '',
      },
      operators: ConditionOperatorGroup.Numeric,
      input: 'Number',
      inputPlaceholder: 'e.g. 4096',
      conversionMultiplier: 1024 * 1024,
    },
  },
  CPU: {
    'cpu.usage': {
      label: 'CPU usage (%)',
      defaultOperator: '>',
      valueKey: 'numberValue',
      defaultValue: {
        stringValue: undefined,
        numberValue: '',
      },
      operators: ConditionOperatorGroup.Numeric,
      input: 'Number',
      inputPlaceholder: 'e.g. 90',
    },
  },
  Disk: {
    'disk.usage': {
      label: 'Disk space used (%)',
      defaultOperator: '>',
      valueKey: 'numberValue',
      defaultValue: {
        stringValue: undefined,
        numberValue: '',
      },
      operators: ConditionOperatorGroup.Numeric,
      input: 'Number',
      inputPlaceholder: 'e.g. 80',
    },
    'disk.used': {
      label: 'Disk space usage (GB)',
      defaultOperator: '>',
      valueKey: 'numberValue',
      defaultValue: {
        stringValue: undefined,
        numberValue: '',
      },
      operators: ConditionOperatorGroup.Numeric,
      input: 'Number',
      inputPlaceholder: 'e.g. 20',
      conversionMultiplier: 1024 * 1024 * 1024,
    },
  },
};
