import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import * as Yup from 'yup';

import { useAuth } from '../../../../hooks';

import { LoginForm } from './LoginForm';

type FormValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [defaultEmail] = useLocalStorage('auth.email', '');
  const initialValues: FormValues = {
    email: defaultEmail ?? '',
    password: '',
  };

  const submit = async ({ email, password }: FormValues, { setFieldValue }: FormikHelpers<FormValues>) => {
    try {
      const result = await login(email, password);

      if (!result) {
        setError(true);
        await setFieldValue('password', '');

        return;
      }
    } catch (e) {
      setError(true);
      await setFieldValue('password', '');
      return;
    }

    navigate('/');
  };

  return (
    <>
      <Helmet title={t('auth.login.pageTitle')} />

      <div className="text-center">
        <h1 className="mb-2 text-2xl font-semibold text-slate-900">{t('auth.login.title')}</h1>
        <p className="mb-6 px-2 text-sm text-slate-500">{t('auth.login.subtitle')}</p>
      </div>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {(props) => <LoginForm error={error} {...props} />}
      </Formik>

      <div className="mt-6 flex flex-col items-center text-center">
        <p className="text-sm text-slate-500">
          Don't have an account?{' '}
          <a
            href="https://stackscout.com/pricing?ref=app-login"
            target="_blank"
            rel="noopener noreferrer"
            className="u-ring rounded-sm font-medium text-primary underline-offset-4 ring-offset-4 transition hover:underline"
          >
            Start 14-day free trial
          </a>
        </p>
        <p className="mt-1 text-sm text-slate-500">
          By continuing, you agree to our{' '}
          <a
            href="https://stackscout.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="u-ring rounded-sm underline underline-offset-4 ring-offset-4 hover:text-primary"
          >
            Terms
          </a>{' '}
          and{' '}
          <a
            href="https://stackscout.com/policy"
            target="_blank"
            rel="noopener noreferrer"
            className="u-ring rounded-sm underline underline-offset-4 ring-offset-4 hover:text-primary"
          >
            Policy
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default LoginPage;
