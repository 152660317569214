/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */

export const openHelpPage = (pageId: string) => {
  (window as any).Beacon?.('article', pageId, { type: 'modal' });
};

export const openHelpSidebar = () => {
  (window as any).Beacon?.('navigate', '/ask/message/');
  (window as any).Beacon?.('open');
};

export const searchHelpDocs = (query: string) => {
  (window as any).Beacon?.('search', query);
  (window as any).Beacon?.('open');
};
