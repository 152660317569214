import { useButton } from '@react-aria/button';
import { FocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { forwardRef, PropsWithChildren, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { Tooltip } from '../Tooltip';

export type ButtonProps = PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> & {
  tooltip?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ tooltip, children, className, onClick, ...props }, forwardedRef) => {
  const localRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    mergeProps(props, {
      isDisabled: props.disabled,
      onPress: onClick,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any,
    localRef,
  );

  const button = (
    <button {...buttonProps} ref={mergeRefs([localRef, forwardedRef])} className={className}>
      {children}
    </button>
  );

  return <FocusRing focusRingClass="u-focus-ring">{tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button}</FocusRing>;
});
Button.displayName = 'Button';

export default Button;
