import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { useSearchParam } from 'react-use';
import * as Yup from 'yup';

import { config } from '../../../../config';
import { useRequestUserPasswordReset } from '../../../../hooks';
import { ReturnToSignInLink } from '../../components';

import { ResetPasswordForm } from './ResetPasswordForm';

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const ResetPasswordPage = () => {
  const email = useSearchParam('email') ?? '';
  const { mutate: request, isSuccess, error } = useRequestUserPasswordReset();
  const initialValues: FormValues = {
    email,
  };

  const submit = async ({ email }: FormValues) => request({ email, client: config.clientApp });

  return (
    <>
      <Helmet title="Reset password" />

      {isSuccess ? (
        <div className="text-center">
          <h1 className="mb-2 text-2xl font-semibold text-slate-900">Forgotten password</h1>
          <p className="mb-6 px-2 text-sm text-slate-500">A password reset link has been sent to {email}.</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <h1 className="mb-2 text-2xl font-semibold text-slate-900">Forgotten password</h1>
            <p className="mb-6 px-2 text-sm text-slate-500">Enter your email address and we'll send you a link to reset your password.</p>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {(props) => <ResetPasswordForm error={error} {...props} />}
          </Formik>
        </>
      )}

      <ReturnToSignInLink />
    </>
  );
};

export default ResetPasswordPage;
