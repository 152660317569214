import { NotificationChannelType } from '../../../api';
import { Badge } from '../../ui';

type Props = {
  type: NotificationChannelType;
};

const labelMap: Record<NotificationChannelType, string> = {
  [NotificationChannelType.SMS]: 'SMS',
  [NotificationChannelType.EMAIL]: 'Email',
  [NotificationChannelType.SLACK]: 'Slack',
  [NotificationChannelType.PAGER_DUTY]: 'PagerDuty',
  [NotificationChannelType.WEBHOOK]: 'Webhook',
};

const NotificationChannelTypeBadge = ({ type }: Props) => <Badge label={labelMap[type]} />;

export default NotificationChannelTypeBadge;
