import { AlertPolicyStatus } from '../../../api';
import { Badge, BadgeVariant } from '../../ui';

type Props = {
  status: AlertPolicyStatus;
  isLoading?: boolean;
};

const labelMap: Record<AlertPolicyStatus, string> = {
  [AlertPolicyStatus.ACTIVE]: 'Active',
  [AlertPolicyStatus.DISABLED]: 'Disabled',
  [AlertPolicyStatus.EXPIRED]: 'Expired',
};

const variantMap: Record<AlertPolicyStatus, BadgeVariant> = {
  [AlertPolicyStatus.ACTIVE]: 'positive',
  [AlertPolicyStatus.DISABLED]: 'negative',
  [AlertPolicyStatus.EXPIRED]: 'ghost',
};

const AlertPolicyStatusBadge = ({ status, isLoading }: Props) => (
  <Badge variant={variantMap[status]} label={labelMap[status]} isLoading={isLoading} />
);

export default AlertPolicyStatusBadge;
