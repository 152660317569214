import { useQueryClient } from '@tanstack/react-query';
import { defer, Params } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { useVerifyResetPasswordTokenFactory } from '../../../../hooks';

import { ChangePasswordPageData } from './types';


export const useChangePasswordPageLoader = () => {
  const queryClient = useQueryClient();
  const email = useSearchParam('email') ?? '';
  const verifyResetPasswordTokenQueryFn = useVerifyResetPasswordTokenFactory();

  return ({ params }: { params: Params<'token'> }) => {
    const verifyResetPasswordTokenPromise = queryClient.fetchQuery(verifyResetPasswordTokenQueryFn(email, params.token!));

    const dataPromise: Promise<ChangePasswordPageData> = Promise.all([verifyResetPasswordTokenPromise]).then(() => ({
      email,
      token: params.token!,
    }));

    return defer({
      data: dataPromise,
    });
  };
};
