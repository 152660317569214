import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { config } from './config';

if (config.telemetryEnabled && config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/localhost:5173/,
      /^https:\/\/api\.stackscout\.com/,
      /^https:\/\/api\.stage\.stackscout\.com/,
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: config.environment,
  });
}
