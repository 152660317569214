export enum QueryKey {
  ACTIVE_INCIDENTS_COUNT = 'active_incidents_count',
  AGENT_INSTALLATION_STATUS = 'agent_installation_status',
  ALERT_POLICIES = 'alert_policies',
  ARCHIVED_SERVERS = 'archived_servers',
  DATA_GRID_REPORT = 'data_grid_report',
  DOWNTIME_REPORT = 'downtime_report',
  HEALTH_MAP_REPORT = 'health_map_report',
  INCIDENTS = 'incidents',
  INCIDENT_SEVERITIES = 'incident_severities',
  INCIDENT_SEVERITY_SUMMARIES = 'incident_severity_summaries',
  INCIDENT_STATS = 'incident_stats',
  NOTIFICATION_CHANNELS = 'notification_channels',
  RESET_PASSWORD_TOKEN_VERIFICATION = 'reset_password_token_verification',
  SERVERS = 'servers',
  SERVER_AVAILABILITY = 'server_availability',
  SERVER_DISKS = 'server_disks',
  SERVER_MEASUREMENTS = 'server_measurements',
  SERVER_NETWORK = 'server_network',
  SERVER_SUMMARIES = 'server_summaries',
  SUBSCRIPTION_PLANS = 'subscription_plans',
  TAGS = 'tags',
  TIME_ZONES = 'time_zones',
  USERS = 'users',
}
