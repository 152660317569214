import { Field, FieldAttributes, useField } from 'formik';

import { cn } from '../../../../utils';

const InputField = <T,>({ className, name, ...rest }: FieldAttributes<T>) => {
  const [, { error, touched }] = useField(name);

  return (
    <Field
      className={cn('u-input', className, {
        'u-input--error': error && touched,
      })}
      name={name}
      {...rest}
    />
  );
};

export default InputField;
