import { createContext, PropsWithChildren, useMemo, useState } from 'react';

type FullScreenContextValue = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
};

const FullScreenContext = createContext<FullScreenContextValue>({
  isFullScreen: false,
  toggleFullScreen: () => void 0,
});

const FullScreenProvider = ({ children }: PropsWithChildren) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const value = useMemo(
    () => ({
      isFullScreen,
      toggleFullScreen() {
        setIsFullScreen((prev) => !prev);
      },
    }),
    [isFullScreen],
  );

  return <FullScreenContext.Provider value={value}>{children}</FullScreenContext.Provider>;
};

export { FullScreenContext, FullScreenProvider };
