import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Await, useLoaderData } from 'react-router-dom';

import { DataErrorScreen, PageSpinner } from '../../ui';

type Props<TData> = {
  title?: string;
  Component: React.ComponentType<{ data: TData }>;
  ErrorElement?: React.ReactNode;
};

const AsyncPage = <T,>({ title, Component, ErrorElement }: Props<T>) => {
  const loader = useLoaderData() as { data: T };

  return (
    <>
      {title && <Helmet title={title} />}
      <Suspense fallback={<PageSpinner />}>
        <Await resolve={loader.data} errorElement={ErrorElement ?? <DataErrorScreen />}>
          {(data: T) => <Component data={data} />}
        </Await>
      </Suspense>
    </>
  );
};

export default AsyncPage;
