import mixpanel from 'mixpanel-browser';
import { PropsWithChildren, useEffect } from 'react';

import { config } from '../config';

const TelemetryWrappedContent = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (config.mixpanelToken) {
      mixpanel.init(config.mixpanelToken, {
        debug: config.environment === 'development',
        track_pageview: false,
        persistence: 'localStorage',
      });
    }
  }, []);

  return <>{children}</>;
};

export const TelemetryProvider = ({ children }: PropsWithChildren) =>
  config.telemetryEnabled ? <TelemetryWrappedContent>{children}</TelemetryWrappedContent> : <>{children}</>;
