import { format } from 'date-fns';
import * as Yup from 'yup';

import { AlertPolicyTargetType, AutocloseUnit, CooldownUnit, CreateAlertPolicyRequest } from '../../../api';
import ConditionBuilderService from '../../../services/ConditionsBuilderService';
import { AlertPolicyFormValues } from '../types';

export interface AlertPolicyCondition {
  id: string;
  key: string;
  operator: string;
  stringValue?: string;
  numberValue?: number | string;
}

export enum ConditionOperatorGroup {
  Numeric,
  Selection,
}

export interface ConditionOperatorsConfig {
  [group: string]: Array<{ key: string; label: string }>;
}

export interface ConditionsConfig {
  [group: string]: {
    [key: string]: ConditionsMeasurementConfig;
  };
}

export interface ConditionsMeasurementConfig {
  label: string;
  operators: ConditionOperatorGroup;
  input: 'Select' | 'Number';
  defaultOperator: string;
  valueKey: 'stringValue' | 'numberValue';
  defaultValue: {
    stringValue?: string;
    numberValue?: number | string;
  };
  inputPlaceholder?: string;
  selectOptions?: Array<{ key: string; label: string }>;
  conversionMultiplier?: number; // number the user input shall be multiplied by before applying to raw data
}

export const alertPolicyValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a policy name'),
  expiryDate: Yup.string()
    .nullable()
    .when('expiryEnabled', {
      is: true,
      then: (schema) => schema.required('Please provide a valid expiry date'),
    }),
  expiryTime: Yup.string().when('expiryEnabled', {
    is: true,
    then: (schema) => schema.required('Please provide a valid expiry time'),
  }),
  customCooldownDuration: Yup.number().when(['cooldownEnabled', 'cooldownDuration'], {
    is: (cooldownEnabled: boolean, cooldownDuration: '1m' | '5m' | 'custom') => cooldownEnabled && cooldownDuration === 'custom',
    then: (schema) =>
      schema
        .typeError('Please provide a valid cooldown time')
        .required('Please provide a valid cooldown time')
        .min(1, 'Cooldown time must be equal or greater than 1'),
  }),
  customAutocloseDuration: Yup.number().when(['autocloseEnabled', 'autocloseDuration'], {
    is: (autocloseEnabled: boolean, autocloseDuration: '24h' | '48h' | 'custom') => autocloseEnabled && autocloseDuration === 'custom',
    then: (schema) =>
      schema
        .typeError('Please provide a valid auto close time')
        .required('Please provide a valid auto close time')
        .min(1, 'Autoclose time must be equal or greater than 1'),
  }),
  serverIds: Yup.array().when('targetType', {
    is: AlertPolicyTargetType.SERVERS,
    then: (schema) => schema.min(1, 'Select at least one server'),
  }),
  tagIds: Yup.array().when('targetType', {
    is: AlertPolicyTargetType.TAGS,
    then: (schema) => schema.min(1, 'Select at least one tag'),
  }),
  conditionArray: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('Select a measurement'),
      operator: Yup.string().required('Select an operator'),
      stringValue: Yup.string().when('key', {
        is: 'status',
        then: (schema) => schema.required('Please select a desired state'),
      }),
      numberValue: Yup.number().when('key', {
        is: 'status',
        otherwise: (schema) =>
          schema
            .required('Please provide a value to compare against')
            .typeError('Please provide a valid number')
            .min(0.01, 'Please provide a value greater than 0'),
      }),
    }),
  ),
  notificationChannelIds: Yup.array().min(1, 'Select at least one notification channel'),
});

export const mapAlertPolicyFormValuesToRequest = ({
  defaultIncidentSeverity,
  expiryEnabled,
  expiryDate,
  expiryTime,
  expiryTimezone,
  cooldownEnabled,
  cooldownDuration,
  customCooldownDuration,
  customCooldownDurationUnit,
  autocloseEnabled,
  autocloseDuration,
  customAutocloseDuration,
  customAutocloseDurationUnit,
  serverIds,
  tagIds,
  notificationChannelIds,
  conditionsMatchType,
  conditionArray,
  ...rest
}: AlertPolicyFormValues): CreateAlertPolicyRequest => ({
  defaultIncidentSeverityId: defaultIncidentSeverity?.id ?? null,
  ...mapExpiryFormValuesToRequestFields({ expiryEnabled, expiryDate, expiryTime, expiryTimezone }),
  ...mapCooldownFormValuesToRequestFields({ cooldownEnabled, cooldownDuration, customCooldownDuration, customCooldownDurationUnit }),
  ...mapAutoCloseFormValuesToRequestFields({ autocloseEnabled, autocloseDuration, customAutocloseDuration, customAutocloseDurationUnit }),
  conditions: mapConditionsFormValuesToRequestFields({ conditionsMatchType, conditionArray }),
  servers: serverIds,
  tags: tagIds,
  notificationChannels: notificationChannelIds,
  ...rest,
});

export const mapAutoCloseFormValuesToRequestFields = ({
  autocloseEnabled,
  autocloseDuration,
  customAutocloseDuration,
  customAutocloseDurationUnit,
}: Pick<AlertPolicyFormValues, 'autocloseEnabled' | 'autocloseDuration' | 'customAutocloseDuration' | 'customAutocloseDurationUnit'>): Pick<
  CreateAlertPolicyRequest,
  'autocloseTime' | 'autocloseUnit'
> => {
  if (!autocloseEnabled) {
    return { autocloseTime: null, autocloseUnit: AutocloseUnit.HOURS };
  }

  switch (autocloseDuration) {
    case '24h':
      return { autocloseTime: 24, autocloseUnit: AutocloseUnit.HOURS };
    case '48h':
      return { autocloseTime: 48, autocloseUnit: AutocloseUnit.HOURS };
    case 'custom':
      return { autocloseTime: parseInt(customAutocloseDuration), autocloseUnit: customAutocloseDurationUnit };
  }
};

export const mapConditionsFormValuesToRequestFields = ({
  conditionsMatchType,
  conditionArray,
}: Pick<AlertPolicyFormValues, 'conditionsMatchType' | 'conditionArray'>) =>
  ConditionBuilderService.generateJsonLogic(conditionsMatchType, conditionArray);

export const mapCooldownFormValuesToRequestFields = ({
  cooldownEnabled,
  cooldownDuration,
  customCooldownDuration,
  customCooldownDurationUnit,
}: Pick<AlertPolicyFormValues, 'cooldownEnabled' | 'cooldownDuration' | 'customCooldownDuration' | 'customCooldownDurationUnit'>): Pick<
  CreateAlertPolicyRequest,
  'cooldownTime' | 'cooldownUnit'
> => {
  if (!cooldownEnabled) {
    return { cooldownTime: null, cooldownUnit: CooldownUnit.HOURS };
  }

  switch (cooldownDuration) {
    case '1m':
      return { cooldownTime: 1, cooldownUnit: CooldownUnit.MINUTES };
    case '5m':
      return { cooldownTime: 5, cooldownUnit: CooldownUnit.MINUTES };
    case 'custom':
      return { cooldownTime: parseInt(customCooldownDuration), cooldownUnit: customCooldownDurationUnit };
  }
};

export const mapExpiryFormValuesToRequestFields = ({
  expiryEnabled,
  expiryDate,
  expiryTime,
  expiryTimezone,
}: Pick<AlertPolicyFormValues, 'expiryEnabled' | 'expiryDate' | 'expiryTime' | 'expiryTimezone'>): Pick<
  CreateAlertPolicyRequest,
  'expiryDate' | 'expiryTime' | 'expiryTimezone'
> => {
  if (!expiryEnabled) {
    return { expiryDate: null, expiryTime: null, expiryTimezone: null };
  }

  return { expiryDate: format(expiryDate!, 'yyyy-MM-dd'), expiryTime, expiryTimezone: expiryTimezone!.id };
};
