import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageSpinner = () => (
  <div className="flex h-full items-center justify-center" role="progressbar">
    <FontAwesomeIcon icon={faSpinnerThird} spin className="h-8 w-8 text-primary" />
  </div>
);

export default PageSpinner;
