import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isNil from 'lodash-es/isNil';
import { useSelector } from 'react-redux';

import { UserPreferences, UserProfile } from '../api';

import { RootState } from '.';

export interface AuthSliceState {
  token?: Nullable<string>;
  user: Nullable<UserProfile>;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, user: null } as AuthSliceState,
  reducers: {
    setToken(state, { payload: token }: PayloadAction<Nullable<string>>) {
      state.token = token;
    },

    clearToken(state) {
      state.token = null;
    },

    setUser(state, { payload: user }: PayloadAction<Nullable<UserProfile>>) {
      state.user = user;
    },

    updateUserProfile(state, { payload: user }: PayloadAction<UserProfile>) {
      state.user = { ...state.user, ...user };
    },

    updateUserPreferences(state, { payload: preferences }: PayloadAction<Partial<UserPreferences>>) {
      if (state.user) {
        state.user.preferences = { ...state.user.preferences, ...preferences };
      }
    },

    updateAccountApiKey(state, { payload: apiKey }: PayloadAction<string>) {
      if (state.user) {
        state.user.account.apiKey = apiKey;
      }
    },

    updateAccountSettings(
      state,
      {
        payload,
      }: PayloadAction<{
        billingName: string;
        billingEmail: string;
        defaultLocale: string;
      }>,
    ) {
      if (!state.user) {
        return;
      }

      state.user.account = { ...state.user.account, ...payload };
    },
  },
});

const selectAuth = (state: RootState) => state.auth;

export const getAuthToken = createSelector(selectAuth, ({ token }) => token);
export const getCurrentUser = createSelector(selectAuth, ({ user }) => user);

export const useCurrentUser = () => useSelector(getCurrentUser)!;

const getIsLoggedIn = createSelector(getAuthToken, getCurrentUser, (token, user) => !isNil(token) && !isNil(user));
export const useIsLoggedIn = () => useSelector(getIsLoggedIn);

export const useLocale = () => useSelector(getCurrentUser)?.preferences.locale ?? 'en-US';

export const usePreferences = () => useSelector(getCurrentUser)!.preferences;

export const { setToken, clearToken, setUser, updateUserProfile, updateUserPreferences, updateAccountSettings, updateAccountApiKey } =
  authSlice.actions;

export default authSlice;
