const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 272.4 278.13"
    className="hover:filter-brightness-20 h-9 w-auto transition-all"
    role="img"
    aria-label="StackScout Logo"
    {...props}
  >
    <path d="M34.15 129.23A34.15 34.15 0 000 163.38v62.12h10.83A34.15 34.15 0 0045 191.35v-62.12z" fill="#7ad572" />
    <path d="M91 83.57a34.15 34.15 0 00-34.16 34.15v129h10.84a34.15 34.15 0 0034.16-34.15v-129z" fill="#00bba7" />
    <path d="M147.86 35.63a34.15 34.15 0 00-34.15 34.15v143.5h10.83a34.15 34.15 0 0034.15-34.15V35.63z" fill="#00adcd" />
    <path d="M204.71 49.73a34.15 34.15 0 00-34.15 34.15v169.4h10.83a34.15 34.15 0 0034.15-34.15V49.73z" fill="#00a6e9" />
    <path d="M261.57 0a34.15 34.15 0 00-34.15 34.15v244h10.83A34.15 34.15 0 00272.4 244V0z" fill="#0084d1" />
  </svg>
);

export default Logo;
