import { lazy } from 'react';
import { Helmet } from 'react-helmet-async';

import { AuthProvider } from '../../../context';
import { useIsLoggedIn } from '../../../store/auth.slice';
import { Toaster } from '../../ui';

import { GuestApp } from './GuestApp';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const App = () => {
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <Helmet defaultTitle="StackScout" titleTemplate="%s - StackScout" />

      <AuthProvider>{isLoggedIn ? <AuthenticatedApp /> : <GuestApp />}</AuthProvider>

      <Toaster />
    </>
  );
};

export default App;
