import * as Yup from 'yup';

import { NotificationChannelType } from '../../../api';
import { NotificationChannelFormValues } from '../types';

export const mapNotificationChannelConfig = (
  type: NotificationChannelType,
  values: Omit<NotificationChannelFormValues, 'name' | 'type'>,
) => {
  switch (type) {
    case NotificationChannelType.EMAIL:
      return { email: values.email };
    case NotificationChannelType.SMS:
      return { mobileNumber: values.mobileNumber };
    case NotificationChannelType.WEBHOOK:
      return { method: values.method, url: values.url };
    case NotificationChannelType.SLACK:
      return { slackWebhookUrl: values.slackWebhookUrl, channel: values.channel };
    case NotificationChannelType.PAGER_DUTY:
      return { integrationKey: values.integrationKey };
  }
};

export const notificationChannelValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a channel name'),
  email: Yup.string().when('type', {
    is: NotificationChannelType.EMAIL,
    then: (schema) => schema.required('Please enter an email address').email('Please enter a valid email address'),
  }),
  url: Yup.string().when('type', {
    is: NotificationChannelType.WEBHOOK,
    then: (schema) => schema.required('Please enter a URL').url('Please enter a valid URL'),
  }),
  slackWebhookUrl: Yup.string().when('type', {
    is: NotificationChannelType.SLACK,
    then: (schema) => schema.required('Please enter a webhook URL').url('Please enter a valid URL'),
  }),
  channel: Yup.string().when('type', {
    is: NotificationChannelType.SLACK,
    then: (schema) =>
      schema
        .required('Please enter a channel name')
        .test('is-channel', 'Please enter a valid channel name (should start with #)', (value) => value?.startsWith('#')),
  }),
  integrationKey: Yup.string().when('type', {
    is: NotificationChannelType.PAGER_DUTY,
    then: (schema) => schema.required('Please enter an integration key'),
  }),
});
