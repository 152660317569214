import { NotificationChannelStatus } from '../../../api';
import { Badge, BadgeVariant } from '../../ui';

type Props = {
  status: NotificationChannelStatus;
  isLoading?: boolean;
};

const labelMap: Record<NotificationChannelStatus, string> = {
  [NotificationChannelStatus.ACTIVE]: 'Active',
  [NotificationChannelStatus.MUTED]: 'Muted',
};

const variantMap: Record<NotificationChannelStatus, BadgeVariant> = {
  [NotificationChannelStatus.ACTIVE]: 'positive',
  [NotificationChannelStatus.MUTED]: 'ghost',
};

const NotificationChannelStatusBadge = ({ status, isLoading }: Props) => (
  <Badge variant={variantMap[status]} label={labelMap[status]} isLoading={isLoading} />
);

export default NotificationChannelStatusBadge;
