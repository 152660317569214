import { UserStatus } from '../../../enums';
import { Badge, BadgeVariant } from '../../ui';

type Props = {
  status: UserStatus;
  isLoading?: boolean;
};

const labelMap: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.DISABLED]: 'Disabled',
};

const variantMap: Record<UserStatus, BadgeVariant> = {
  [UserStatus.ACTIVE]: 'positive',
  [UserStatus.DISABLED]: 'ghost',
};

const UserStatusBadge = ({ status, isLoading }: Props) => (
  <Badge label={labelMap[status]} variant={variantMap[status]} isLoading={isLoading} />
);

export default UserStatusBadge;
