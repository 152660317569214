import { QueryKey } from '../../enums';
import { axiosPost } from '../../utils';

export const useVerifyResetPasswordTokenFactory = () => (email: string, token: string) => ({
  queryKey: [QueryKey.RESET_PASSWORD_TOKEN_VERIFICATION, { email, token }],
  queryFn: () => axiosPost('/password-reset-link-validations', { email, token }),
  gcTime: Infinity,
  refetchOnWindowFocus: false,
  retry: false,
});
